import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Form, Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import api from '../../config/configApi';
import getuUserDepartments from '../../utils/local-storage';
import canAccessEditar from '../../utils/acl/editar';

export default function EditarModal(
  {
    id,
    unidadeId,
    unidadeNome,
    dataAgendamento,
    dataAgendamentoHora,
    dataAtendimento,
    dataAtendimentoHora,
    unidades,
    tipoAtendimentoId,
    tiposAtendimento,
    cancelado,
    atendenteId,
    atendentes,
  },
) {
  const [edicao, setEdicao] = useState({
    unidadeId,
    unidadeNome,
    cancelado,
    dataAgendamento,
    dataAgendamentoHora,
    dataAtendimento,
    dataAtendimentoHora,
    tipoAtendimentoId,
    atendenteId,
  });
  const [userDepartments] = useState(getuUserDepartments());
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [alertSuccess, setAlertSuccess] = useState({ isVisible: false });

  const [isEditarEncerrado, setIsEditarEncerrado] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleEditar = async () => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const formIsValid = formRef.current.reportValidity();
      if (formIsValid) {
        const realizadoem = (edicao.dataAtendimento && edicao.dataAtendimentoHora && new Date(`${edicao.dataAtendimento} ${edicao.dataAtendimentoHora}`)) || null;
        await api.patch(`/atendimentos-presenciais/${id}`, {
          unidadeId: edicao.unidadeId,
          cancelado: edicao.cancelado,
          agendadopara: new Date(`${edicao.dataAgendamento} ${edicao.dataAgendamentoHora}`),
          realizadoem,
          usuarioqueatendeuId: edicao.atendenteId || null,
          tipoatendimentoId: edicao.tipoAtendimentoId ?? 1,
        }, headers);
        setIsEditarEncerrado(true);
        setAlertSuccess({ isVisible: true, message: 'Edição realizado com sucesso!' });
      }
    } catch (error) {
      setAlertError({ isVisible: true, message: error.response.data.mensagem || 'Não foi possível editar este agendamento' });
    }
  };

  const perfilMaster = '99';

  const location = useLocation();
  const isEditPessoaPage = location.pathname.includes('/edit-pessoa');

  return (
    <>
      {isEditPessoaPage && (canAccessEditar(userDepartments) || perfilMaster === localStorage.getItem('perfilId')) && (
      <Button variant="secondary m-1" type="button" onClick={handleOpenModal}>
        <span>
          <FontAwesomeIcon icon={faCalendarDays} />
        </span>
        &nbsp;Editar
      </Button>
      )}
      <Modal show={isOpen} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Editar Agendamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="form-remarcar-agendamento" ref={formRef}>
            { alertError.isVisible
              && (
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <Alert variant="danger" onClose={() => setAlertError({ isVisible: false })} dismissible>
                        <p className="text-center mt-3">
                          {alertError.message}
                        </p>
                      </Alert>
                    </div>
                  </div>
                </div>
              )}
            <fieldset disabled={isEditarEncerrado}>
              <div className="form-group row align-items-end">
                <div className="form-group col-md-3">
                  <label htmlFor="unidade">
                    Unidade
                  </label>
                  <Form.Select
                    className="custom-select my-1 mr-sm-2"
                    name="unidade"
                    id="unidade"
                    placeholder="unidade"
                    required="required"
                    value={edicao.unidadeId}
                    onChange={(text) => {
                      setEdicao((prev) => ({
                        ...prev,
                        unidadeId: text.target.value,
                        unidadeNome: unidades.find(
                          (unidade) => unidade.id === +text.target.value,
                        )?.nome,
                      }));
                    }}
                  >
                    <option value="">Selecione a Unidade</option>
                    {unidades?.map((item) => (
                      <option
                        key={item.nome}
                        value={item.id.toString()}
                      >
                        {item.nome}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="datadoagendamento">Data do Agendamento</label>
                  <input
                    type="date"
                    name="datadoagendamento"
                    id="datadoagendamento"
                    className="form-control"
                    value={edicao.dataAgendamento}
                    onChange={(text) => {
                      setEdicao((prev) => ({
                        ...prev,
                        dataAgendamento: text.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="datadoagendamentohora">Hora do Agendamento</label>
                  <input
                    type="time"
                    name="datadoagendamentohora"
                    id="datadoagendamentohora"
                    className="form-control"
                    value={edicao.dataAgendamentoHora}
                    onChange={(text) => {
                      setEdicao((prev) => ({
                        ...prev,
                        dataAgendamentoHora: text.target.value,
                      }));
                    }}
                  />
                </div>

                <div className="form-group col-md-2">
                  <label htmlFor="tipoatendimento">
                    Tipo de Atendimento
                  </label>
                  <select
                    className="custom-select my-1 mr-sm-2"
                    name="tipoatendimento"
                    id="tipoatendimento"
                    value={edicao.tipoAtendimentoId}
                    onChange={(event) => {
                      const selectedTipoAtendimento = event.target.value;
                      setEdicao((prev) => ({
                        ...prev,
                        tipoAtendimentoId: selectedTipoAtendimento,
                      }));
                    }}
                  >
                    <option value="">Selecione o Tipo de Atendimento</option>
                    {tiposAtendimento?.map((ta) => (
                      <option
                        key={ta.id}
                        value={ta.id}
                      >
                        {ta.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-2">
                  <label htmlFor="cancelado">Cancelado</label>
                  <select
                    className="custom-select my-1 mr-sm-2"
                    name="cancelado"
                    id="cancelado"
                    value={edicao.cancelado?.toString() === 'true'}
                    onChange={(event) => {
                      const isCanceled = event.target.value === 'true';
                      let atendimentoUpdate = {};
                      if (isCanceled) {
                        atendimentoUpdate = {
                          dataAtendimento: null,
                          dataAtendimentoHora: null,
                          atendenteId: null,
                        };
                      }
                      setEdicao((prev) => ({
                        ...prev,
                        cancelado: isCanceled,
                        ...atendimentoUpdate,
                      }));
                    }}
                  >
                    <option value="false">Não</option>
                    <option value="true">Sim</option>
                  </select>
                </div>
                {!edicao.cancelado && (
                <>
                  <div className="form-group col-md-3">
                    <label htmlFor="dataAtendimento">Data do Atendimento</label>
                    <input
                      type="date"
                      name="dataAtendimento"
                      id="dataAtendimento"
                      className="form-control"
                      value={edicao.dataAtendimento}
                      onChange={(text) => {
                        setEdicao((prev) => ({
                          ...prev,
                          dataAtendimento: text.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <label htmlFor="horaAtendimento">Hora do Atendimento</label>
                    <input
                      type="time"
                      name="horaAtendimento"
                      id="horaAtendimento"
                      className="form-control"
                      value={edicao.dataAtendimentoHora}
                      onChange={(text) => {
                        setEdicao((prev) => ({
                          ...prev,
                          dataAtendimentoHora: text.target.value,
                        }));
                      }}
                    />
                  </div>
                </>
                )}
                {!edicao.cancelado && edicao.dataAtendimento && edicao.dataAtendimentoHora && (
                <div className="col-xl-3 col-md-6 mb-4">
                  <label htmlFor="inputEmail4">Atendente</label>
                  <select
                    className="custom-select mr-sm-2"
                    name="atendenteId"
                    id="atendenteId"
                    defaultValue={atendenteId}
                    onChange={(text) => {
                      setEdicao((prev) => ({
                        ...prev,
                        atendenteId: text.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="" />
                    {atendentes?.atendentes?.map((item) => (
                      <option
                        key={item.id}
                        value={item.id}
                        selected={item.id === atendenteId}
                      >
                        {item.nome}
                      </option>
                    ))}
                  </select>
                </div>
                )}
              </div>
            </fieldset>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {alertSuccess.isVisible && (
            <div className="alert alert-success text-center" role="alert">
                {alertSuccess.message}
            </div>
          )}
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleEditar}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

EditarModal.propTypes = {
  id: PropTypes.number.isRequired,
  unidadeId: PropTypes.number.isRequired,
  unidadeNome: PropTypes.string.isRequired,
  dataAgendamento: PropTypes.string.isRequired,
  dataAgendamentoHora: PropTypes.string.isRequired,
  dataAtendimento: PropTypes.string.isRequired,
  dataAtendimentoHora: PropTypes.string.isRequired,
  cancelado: PropTypes.bool.isRequired,
  atendenteId: PropTypes.number.isRequired,
  unidades: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    nome: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tiposAtendimento: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  atendentes: PropTypes.object.isRequired,
  tipoAtendimentoId: PropTypes.number.isRequired,
};
