import JanelaCentro from './centro';
import JanelaAlcantara from './alcantara';
import JanelaTaquara from './taquara';
import JanelaNilopolis from './nilopolis';
import JanelaCampoGrande from './campo-grande';
import JanelaSaoPaulo from './sao-paulo';
import JanelaBelohorizonte from './belo-horizonte';
import JanelaAngraDosReis from './angra-dos-reis';

export default class JanelasManager {
  static getJanelasByUnidade(unidadeNome) {
    switch (unidadeNome) {
      case 'CENTRO':
        return JanelaCentro.getJanelasAtendimento();
      case 'ALCÂNTARA':
        return JanelaAlcantara.getJanelasAtendimento();
      case 'TAQUARA':
        return JanelaTaquara.getJanelasAtendimento();
      case 'NILÓPOLIS':
        return JanelaNilopolis.getJanelasAtendimento();
      case 'CAMPO GRANDE':
        return JanelaCampoGrande.getJanelasAtendimento();
      case 'SÃO PAULO':
        return JanelaSaoPaulo.getJanelasAtendimento();
      case 'BELO HORIZONTE':
        return JanelaBelohorizonte.getJanelasAtendimento();
      case 'ANGRA DOS REIS':
        return JanelaAngraDosReis.getJanelasAtendimento();
      default:
        return [];
    }
  }
}
