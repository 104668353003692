import { object, string } from 'yup';
import { validateCPF } from '../../../utils/documentos';

const schema = object().shape({
  nome: string().required('Esse campo deve ser preenchido.'),
  email: string().email('Email invalido').optional().nullable(true),
  cpf: string().test(('cpf', 'CPF invalido', (value) => validateCPF(value))),
  senhaINSS: string().optional().nullable(true),
  cep: string().optional().nullable(true).length(8),
  assinouContratoAt: string()
    .nullable() // Allow null by default
    .when('assinouContrato', {
      is: (assinouContrato) => assinouContrato === 'S' || assinouContrato === 'N',
      then: (s) => s.required('Esse campo deve ser preenchido'), // Non-nullable when 'S' or 'N'
      otherwise: (s) => s.nullable().notRequired(), // Nullable and not required when other values
    }),
  rg: string(),
  cadUnico: string().optional().nullable(true),
  isIdoso: string().optional().nullable(true),
  isDeficiente: string().optional().nullable(true),
  isEstrangeiro: string().optional().nullable(true),
  logradouro: string().min(3).optional().nullable(true),
  cidade: string().min(3).optional().nullable(true),
  bairro: string().min(3).optional().nullable(true),
  numero: string().optional().nullable(true),
  estado: string().optional().nullable(true),
  escolaridade: string().optional().nullable(true),
  beneficio: string().required(),
  advogadoId: string().optional().nullable(true),
  observacaoContrato: string().optional().nullable(true),
  assinouContrato: string().optional().nullable(true),
  procurador: object().shape({
    nome: string().optional().nullable(true),
    cpf: string().optional().nullable(true).test(('cpf', 'CPF invalido', (value) => {
      if (value) return validateCPF(value);
      return true;
    })),
    rg: string().optional().nullable(true),
    profissao: string().optional().nullable(true),
    isEstrangeiro: string().optional().nullable(true),
    logradouro: string().min(3).nullable(true),
    cidade: string().min(3).optional().nullable(true),
    bairro: string().min(3).optional().nullable(true),
    numero: string().optional().nullable(true),
    estado: string().optional().nullable(true),
    cep: string().optional().nullable(true).length(8),
  }).optional().nullable(true),
});

export default schema;
