import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import {
  Button, Modal, Tabs, Tab,
  Form,

  Alert, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import pdfMake from 'pdfmake/build/pdfmake';
import Accordion from 'react-bootstrap/Accordion';
import SituacaoTooltip from './SituacaoTooltip';
import { Navbar } from '../../../components/Navbar';
import { Agenda } from '../../../components/Agenda';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';
import 'moment/locale/pt-br';
import api from '../../../config/configApi';
import { servDeleteUser } from '../../../services/servDeleteUser';
import { ChangeAlert } from '../../Shared/ChangeAlert';
import pdfFonts from '../../../utils/fonts/vfs_fonts';
import { formularioPadraoContent } from './formularioPadrao';
import HistoricoAgendamentoTimeline from '../../AtendimentosPresenciais/HistoricoTimeline';
import EditPessoaAgendamento from './Agendamento';
import EditPessoaEtapas from './Etapas';

import Recebimento from './Recebimento';
import AtendimentoForm from '../../AtendimentosPresenciais/Atendimento/AtendimentoForm';
import getCep from '../../../utils/cep';
import estados from '../../../utils/estados';
import specialCharacters from '../../../utils/generic';
import personStatus from '../../../utils/enums/personStatus';
import paymentStatus from '../../../utils/enums/paymentsStatus';
import getuUserDepartments from '../../../utils/local-storage';
import canAccessContasAReceber from '../../../utils/acl/contas-a-receber';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const fonts = {
  Arial: {
    normal: 'ARIAL.TTF',
    bold: 'ARIALBD.TTF',
    italics: 'ARIALI.TTF',
    bolditalics: 'ARIALBI.TTF',
  },
};

// eslint-disable-next-line import/prefer-default-export
export function EditPessoa(props) {
  const history = useHistory();
  const moment = require('moment');

  const [id] = useState(props.match.params.id);
  const [tabKey, setTabKey] = useState('preAtendimento');
  const [nome, setNome] = useState('');
  const [unidade, setUnidade] = useState('');
  const [atendenteId, setAtendenteId] = useState('');
  const [origemdasligacoes, setOrigemDasLigacoes] = useState('');
  const [datadasligacoes, setDataDasLigacoes] = useState('');

  const [telefoneprincipal, setTelefoneprincipal] = useState('');
  const [obstelefoneprincipal, setObstelefoneprincipal] = useState('');

  const [telefonesegundo, setTelefonesegundo] = useState('');
  const [obstelefonesegundo, setObstelefonesegundo] = useState('');

  const [telefoneoutros, setTelefoneoutros] = useState('');
  const [obstelefoneoutros, setObstelefoneoutros] = useState('');

  const [datanascimento, setDataNascimento] = useState(null);

  const [datadoagendamento, setDataDoAgendamento] = useState('');
  const [datadoagendamentohora, setDataDoAgendamentoHora] = useState('');
  const [beneficio, setBeneficio] = useState('');
  const [indicacao, setIndicacao] = useState('');
  const [observacaoindicacao, setObservacaoindicacao] = useState('');
  const [atendimentosPresenciais, setAtendimentosPresenciais] = useState([]);

  const [page, setPage] = useState(1);
  const [unidades, setUnidades] = useState([]);
  const [unidadeId, setUnidadeId] = useState('');
  const [unidadesOptions, setUnidadesOptions] = useState(['']);
  const [beneficios, setBeneficios] = useState([]);
  const [origens, setOrigens] = useState([]);
  const [atendentes, setAtendentes] = useState([]);
  const [indicacoes, setIndicacoes] = useState([]);
  const [lastPage, setLastPage] = useState('');

  const [formDirty, setFormDirty] = React.useState(false);
  const [dirtyCalledTimes, setDirtyCalledTimes] = React.useState(0);
  const [show, setShow] = useState(false);
  const [isDisabledFormularioPadrao, setIsDisabledFormularioPadrao] = useState(false);
  const [showChangeAlert, setShowChangeAlert] = useState(false);
  const [pessoa, setPessoa] = useState({});
  const [estado, setEstado] = useState('');
  const [numero, setNumero] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [cep, setCep] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [complemento, setComplemento] = useState('');
  const [observacao, setObservacao] = useState('');
  const [situacao, setSituacao] = useState('');
  const [situacaoInputText, setSituacaoInputText] = useState('Selecione uma opção');

  const [pagamento, setPagamento] = useState('');
  const [pagamentoInputText, setPagamentoInputText] = useState('');
  const [creator, setCreator] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [alertError, setAlertError] = useState({ isVisible: false });
  const [alertSuccess, setAlertSuccess] = useState({ isVisible: false });
  const [floatAlertSuccess, setFloatAlertSuccess] = useState({ isVisible: false });
  const [formKey, setFormKey] = useState(0);
  const [userDepartments] = useState(getuUserDepartments());
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const renderOptions = () => Object.entries(SituacaoTooltip.opcoes).map(([categoria, opcoes]) => (
    <div key={categoria}>
      <strong>
        {categoria}
        :
      </strong>
      <ul>
        {Object.entries(opcoes).map(([chaveOpcao, valorOpcao]) => (
          <li key={chaveOpcao}>
            {chaveOpcao.replace(/_/g, ' ')}
            :
            {valorOpcao || 'Sem descrição'}
          </li>
        ))}
      </ul>
    </div>
  ));

  const situacaoOptions = [
    {
      label: 'Análise',
      options: Object.entries(personStatus.ANALISE).map(([code, name]) => ({
        value: code,
        label: name,
      })),
    },
    {
      label: 'Decisão',
      options: Object.entries(personStatus.DECISAO).map(([code, name]) => ({
        value: code,
        label: name,
      })),
    },
    {
      label: 'Interação',
      options: Object.entries(personStatus.INTERACAO).map(([code, name]) => ({
        value: code,
        label: name,
      })),
    },
    {
      label: 'Processo',
      options: Object.entries(personStatus.PROCESSO).map(([code, name]) => ({
        value: code,
        label: name,
      })),
    },
    {
      label: 'Outros',
      options: Object.entries(personStatus.OUTROS).map(([code, name]) => ({
        value: code,
        label: name,
      })),
    },
  ];

  const handleChange = (selectedOption) => {
    setSituacao(selectedOption ? selectedOption.value : '');
    setSituacaoInputText(selectedOption ? selectedOption.label : 'Pesquisar...');
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      fontSize: '12px', // Ajuste o tamanho da fonte aqui
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '12px', // Ajuste o tamanho da fonte aqui também
    }),
  };

  const searchCep = async (code) => {
    const cep = await getCep(code);
    if (cep.logradouro) {
      const logradouro = document.getElementById('logradouro');
      logradouro.value = cep.logradouro.toUpperCase();
      setLogradouro(logradouro.value);
    }
    if (cep.localidade) {
      const cidade = document.getElementById('cidade');
      cidade.value = cep.localidade.toUpperCase();
      setCidade(cidade.value);
    }
    if (cep.uf) {
      const estado = document.getElementById('estado');
      estado.value = cep.uf.toUpperCase();
      setEstado(estado.value);
    }
    if (cep.bairro) {
      const bairro = document.getElementById('bairro');
      bairro.value = cep.bairro.toUpperCase();
      setBairro(bairro.value);
    }
  };

  const situacaoTooltip = (
    <Tooltip id="situacaoTooltip">
      <strong>{situacaoInputText}</strong>
    </Tooltip>
  );

  const markFormDirty = () => {
    setFormDirty(true);
    setDirtyCalledTimes((prevState) => prevState + 1);
  };

  const generarFormularioPadrao = (event) => {
    const dataFormularioPadrao = [
      nome,
      bairro,
      cidade,
      indicacao,
      beneficio,
      telefoneprincipal,
      telefonesegundo,
      telefoneoutros,
    ];
    const idsFormularioPadrao = [
      'nome',
      'bairro',
      'cidade',
      'indicacao',
      'beneficio',
      'telefoneprincipal',
      'telefonesegundo',
      'telefoneoutros',
    ];

    const hasInvalidDataOnFormularioPadrao = dataFormularioPadrao
      .map((data) => !data)
      ?.some((data) => data);

    if (hasInvalidDataOnFormularioPadrao) {
      idsFormularioPadrao.forEach((id) => {
        const element = document.getElementById(id);
        console.log(element.value);
        if (!element?.value) {
          element?.classList?.add('is-invalid');
        }
      });
      setShow(true);
      setIsDisabledFormularioPadrao(true);
    } else {
      const dd = formularioPadraoContent({
        nome,
        bairro,
        cidade,
        indicacao,
        beneficio,
        telefoneprincipal,
        telefonesegundo,
        telefoneoutros,
      });
      pdfMake.createPdf(dd, null, fonts).print();
    }
  };

  useEffect(() => {
    // Whenever pessoa changes, increment the form key to force rerender
    setFormKey((prevKey) => prevKey + 1);
  }, [pessoa]);

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const editUser = async (e) => {
    try {
      e.preventDefault();

      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      await api
        .put(
          '/pessoa/pessoa',
          {
            id,
            nome: nome?.trim(),
            unidadeId,
            atendenteId,
            origemdasligacoes,
            datadasligacoes,
            telefoneprincipal,
            obstelefoneprincipal: obstelefoneprincipal?.trim(),
            telefonesegundo,
            obstelefonesegundo: obstelefonesegundo?.trim(),
            telefoneoutros,
            obstelefoneoutros: obstelefoneoutros?.trim(),
            bairro: bairro?.trim(),
            datanascimento,
            datadoagendamento,
            datadoagendamentohora,
            beneficio,
            indicacao,
            observacaoindicacao: observacaoindicacao?.trim(),
            cidade: cidade?.trim(),
            complemento: complemento?.trim(),
            estado,
            numero,
            logradouro: logradouro?.trim(),
            cep,
            observacao: observacao?.trim(),
            situacaoStatus: situacao || null,
            pagamentoStatus: pagamento || null,
          },
          headers,
        )
        .then((response) => {
          setStatus({
            type: 'redSuccess',
            mensagem: response.data.mensagem,
          });

          getUser();

          const allInputs = document.querySelectorAll('input');

          allInputs.forEach((element) => {
            element.classList.remove('is-invalid');
          });
          setIsDisabledFormularioPadrao(false);
          setFormDirty(false);
          setDirtyCalledTimes(0);
          setAlertSuccess({ isVisible: true, message: 'Dados salvos com sucesso!' });
        });
    } catch (error) {
      setAlertError({ isVisible: true, message: 'Falha ao atualizar pessoa. Por favor tente novamente.' });
    }
  };

  const getUnidades = async () => {
    try {
      const response = await api.get('unidade/unidades');
      const unidadesData = response.data?.unidades;
      const options = unidadesData.map((unidadeItem) => (
        <option key={unidadeItem.id} value={unidadeItem.id} selected={unidadeItem.id === unidadeId}>
          {unidadeItem.nome}
        </option>
      ));
      setUnidades(unidadesData);
      setUnidadesOptions(options);
    } catch (error) {
      if (error.response) {
        setStatus({
          type: 'error',
          mensagem: error.response.data.mensagem,
        });
      } else {
        setStatus({
          type: 'error',
          mensagem: 'Erro: Tente mais tarde!',
        });
      }
    }
  };
  const showFloatAlertSuccess = (message) => {
    setFloatAlertSuccess({ isVisible: true, message });
  };

  const getUser = async () => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await api
      .get(`/pessoa/pessoa/${id}`, headers)
      .then((response) => {
        if (response.data.user) {
          setNome(response.data.user.nome);
          setUnidade(response.data.user.unidade);
          setAtendenteId(response.data.user.atendenteId);
          setCreator(response.data.user?.creator?.nome);
          setOrigemDasLigacoes(response.data.user.origemdasligacoes);
          setDataDasLigacoes(response.data.user.datadasligacoes);
          setTelefoneprincipal(response.data.user.telefoneprincipal);
          setObstelefoneprincipal(response.data.user.obstelefoneprincipal);
          setTelefonesegundo(response.data.user.telefonesegundo);
          setObstelefonesegundo(response.data.user.obstelefonesegundo);
          setTelefoneoutros(response.data.user.telefoneoutros);
          setObstelefoneoutros(response.data.user.obstelefoneoutros);
          setBairro(response.data.user.bairro);
          setCidade(response.data.user.cidade);
          setDataNascimento(response.data.user.datanascimento);
          setDataDoAgendamento(response.data.user.datadoagendamento);
          setDataDoAgendamentoHora(response.data.user.datadoagendamentohora);
          setBeneficio(response.data.user.beneficio);
          setIndicacao(response.data.user.indicacao);
          setObservacaoindicacao(response.data.user.observacaoindicacao);
          setAtendimentosPresenciais(response.data.user.atendimentopresencials);
          setPessoa(response.data.user);
          setLogradouro(response.data.user.logradouro);
          setNumero(response.data.user.numero);
          setCep(response.data.user.cep);
          setComplemento(response.data.user.complemento);
          setEstado(response.data.user.estado);
          setObservacao(response.data.user.observacao);
          setSituacao(response.data.user.situacaoStatus);
          setPagamento(response.data.user.pagamentoStatus);
          setUnidadeId(response.data.user.unidadeId);
          if (!response.data.user.situacaoStatus) {
            setSituacaoInputText('Selecione uma opção');
          } else {
            setSituacaoInputText(response.data.user.situacaoStatus);
          }
        } else {
          setStatus({
            type: 'redWarning',
            mensagem: 'Erro: Usuário não encontrado!',
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'redWarning',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'redWarning',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };

  useEffect(() => {
    getUser();
    getUnidades();
  }, [id]);

  async function validate() {
    const schema = yup.object().shape({
      nome: yup
        .string('Erro: Necessário preencher o campo nome!')
        .required('Erro: Necessário preencher o campo nome!'),
    });

    try {
      await schema.validate({ nome });
      return true;
    } catch (err) {
      setStatus({ type: 'error', mensagem: err.errors });
      return false;
    }
  }

  const deleteUser = async (idUser) => {
    const response = await servDeleteUser(idUser);
    if (response) {
      if (response.type === 'success') {
        setStatus({
          type: 'redSuccess',
          mensagem: response.mensagem,
        });
      } else {
        setStatus({
          type: 'error',
          mensagem: response.mensagem,
        });
      }
    } else {
      setStatus({
        type: 'error',
        mensagem: 'Erro: Tente mais tarde!',
      });
    }
  };

  const [data, setData] = useState([]);

  const getUsersAgenda = async (page) => {
    if (page === undefined) {
      page = 1;
    }
    setPage(page);
    await api
      .post(
        '/pessoa/agenda',
        {
          buscardata: datadoagendamento,
          buscarunidade: unidade,
          buscardatadoagendamentohora: datadoagendamentohora,
        },
      )
      .then((response) => {
        setData(response.data.users);
        setLastPage(response.data.lastPage);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getUsersAgenda();
  }, []);

  const getBeneficios = async () => {
    await api
      .get('/beneficio/beneficios')
      .then((response) => {
        setBeneficios(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getBeneficios();
  }, []);

  const getOrigens = async () => {
    await api
      .get('/origem/origens')
      .then((response) => {
        setOrigens(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getOrigens();
  }, []);

  const getAtendentes = async () => {
    await api
      .get('/user/atendentes')
      .then((response) => {
        setAtendentes(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getAtendentes();
  }, []);

  const getIndicacoes = async () => {
    await api
      .get('/indicacao/indicacoes')
      .then((response) => {
        setIndicacoes(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getIndicacoes();
  }, []);

  return (
    <>
      {floatAlertSuccess.isVisible && (
        <div
          style={{
            position: 'fixed',
            top: 20,
            right: 20,
            zIndex: 1000, // Adjust the z-index to make sure it appears above other content
            width: '300px', // Adjust the width as needed
          }}
        >
          <Alert
            variant="success"
            onClose={() => setFloatAlertSuccess({ isVisible: false })}
            dismissible
          >
            <p className="text-center mt-3">{floatAlertSuccess.message}</p>
          </Alert>
        </div>
      )}

      <div id="wrapper">
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />

            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12 col-md-12 mb-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <div className="row">
                        <div className="col-10">
                          <h4 className="h4 mb-0 text-gray-800">
                            <a href={`/edit-pessoa/${pessoa.id}`}>
                              {pessoa.nome}
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                        className="mb-3"
                      >
                        <Tab eventKey="preAtendimento" title="Pré Atendimento">
                          <form
                            onSubmit={editUser}
                            onChange={markFormDirty}
                            key={formKey}
                          >
                            <div className="row">
                              <div className="col-xl-2 col-md-6 mb-4">
                                <label htmlFor="inputPassword4">
                                  Data Ligação
                                </label>
                                <input
                                  type="date"
                                  name="datadasligacoes"
                                  id="datadasligacoes"
                                  className="form-control"
                                  placeholder=""
                                  defaultValue={datadasligacoes}
                                  onChange={(text) => setDataDasLigacoes(text.target.value)}
                                  required
                                />
                              </div>
                              <div className="col-xl-4 col-md-6 mb-4">
                                <label htmlFor="inputEmail4">
                                  Origem Ligação
                                </label>
                                <select
                                  className="custom-select my-1 mr-sm-2"
                                  name="origemdasligacoes"
                                  id="origemdasligacoes"
                                  placeholder="origemdasligacoes"
                                  defaultValue={origemdasligacoes}
                                  onChange={(text) => setOrigemDasLigacoes(text.target.value)}
                                  required
                                >
                                  <option defaultValue="">
                                    Selecione o Origem
                                  </option>
                                  {origens?.origens?.map((item) => (
                                    <option
                                      key={item.nome}
                                      value={item.nome}
                                      selected={item.nome === origemdasligacoes}
                                    >
                                      {item.nome}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-xl-3 col-md-6 mb-4">
                                <label htmlFor="inputEmail4">Atendente</label>
                                <Select
                                  name="atendenteId"
                                  value={
                                    atendenteId
                                      ? {
                                        value: atendenteId,
                                        label: atendentes?.atendentes?.find(
                                          (item) => item.id === atendenteId,
                                        )?.nome,
                                      }
                                      : null
                                  }
                                  onChange={(selectedOption) => setAtendenteId(selectedOption ? selectedOption.value : '')}
                                  options={atendentes?.atendentes?.map((item) => ({
                                    value: item.id,
                                    label: item.nome,
                                  }))}
                                  isSearchable
                                  placeholder="Pesquisar Atendente"
                                  isClearable
                                />
                              </div>
                              <div className="col-xl-3 col-md-6 mb-4">
                                <label htmlFor="inputEmail4">Benefício</label>
                                <Select
                                  name="beneficio"
                                  value={
                                    beneficio
                                      ? {
                                        value: beneficio,
                                        label: beneficios?.beneficios?.find(
                                          (item) => item.nome === beneficio,
                                        )?.nome,
                                      }
                                      : null
                                  }
                                  onChange={(selectedOption) => setBeneficio(selectedOption ? selectedOption.value : '')}
                                  options={beneficios?.beneficios?.map((item) => ({
                                    value: item.nome,
                                    label: item.nome,
                                  }))}
                                  isSearchable
                                  placeholder="Pesquisar Benefício"
                                  isClearable
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xl-4 col-md-4 mb-4">
                                <label htmlFor="creator">Registrado por</label>

                                <input
                                  type="text"
                                  name="creator"
                                  disabled
                                  className="form-control text-uppercase"
                                  placeholder=""
                                  defaultValue={creator}
                                  required
                                />
                              </div>
                              <div className="col-xl-4 col-md-4 mb-4">
                                <label htmlFor="inputEmail4">Indicação</label>
                                <Select
                                  name="indicacao"
                                  value={
                                    indicacao
                                      ? {
                                        value: indicacao,
                                        label: indicacoes?.indicacoes?.find(
                                          (item) => item.nome === indicacao,
                                        )?.nome,
                                      }
                                      : null
                                  }
                                  onChange={(selectedOption) => setIndicacao(selectedOption ? selectedOption.value : '')}
                                  options={indicacoes?.indicacoes?.map((item) => ({
                                    value: item.nome,
                                    label: item.nome,
                                  }))}
                                  isSearchable
                                  placeholder="Pesquisar Indicação"
                                  isClearable
                                />
                              </div>

                              <div className="col-xl-4 col-md-4 mb-4">
                                <label htmlFor="inputPassword4">
                                  Observação Indicação
                                </label>
                                <input
                                  type="text"
                                  name="observacaoindicacao"
                                  id="observacaoindicacao"
                                  className="form-control"
                                  placeholder="Observação da indicação"
                                  defaultValue={observacaoindicacao}
                                  autoComplete="off"
                                  onChange={(text) => setObservacaoindicacao(
                                    text.target.value.toUpperCase(),
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xl-8 col-md-6 mb-4">
                                <label htmlFor="inputPassword4">Nome*</label>
                                <input
                                  type="text"
                                  name="nome"
                                  id="nome"
                                  className="form-control text-uppercase"
                                  placeholder="Nome completo"
                                  defaultValue={nome}
                                  autoComplete="off"
                                  onChange={
                                    (text) => setNome(text.target.value.toUpperCase())
                                  }
                                  required
                                />
                              </div>

                              <Form.Group className="col-lg-2 col-md-6">
                                <Form.Label>Unidades</Form.Label>
                                <Form.Select
                                  name="unidade"
                                  value={unidadeId}
                                  onChange={(e) => setUnidadeId(e.target.value || null)}
                                >
                                  <option value="">
                                    Selecione uma unidade
                                  </option>
                                  {unidadesOptions}
                                </Form.Select>
                              </Form.Group>

                              <div className="col-xl-2 col-md-6 mb-4">
                                <label htmlFor="inputPassword4">
                                  Data Nascimento
                                </label>
                                <input
                                  type="date"
                                  name="datanascimento"
                                  id="datanascimento"
                                  className="form-control"
                                  autoComplete="on"
                                  defaultValue={datanascimento}
                                  onChange={(text) => setDataNascimento(text.target.value)}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <Form.Group className="col-xl-1 col-md-6 mb-4">
                                <Form.Label>CEP</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="cep"
                                  id="cep"
                                  defaultValue={cep}
                                  onChange={async (e) => {
                                    const cepCode = e.target?.value?.replace(
                                      specialCharacters,
                                      '',
                                    );
                                    if (cepCode.length === 8) await searchCep(cepCode);
                                    e.target.value = cepCode;
                                    setCep(cepCode);
                                  }}
                                />
                              </Form.Group>
                              <Form.Group className="col-xl-2 col-md-6 mb-4">
                                <Form.Label>Logradouro</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="logradouro"
                                  id="logradouro"
                                  defaultValue={logradouro}
                                  autoComplete="off"
                                  onChange={(text) => setLogradouro(
                                    text.target.value.toUpperCase(),
                                  )}
                                />
                              </Form.Group>
                              <Form.Group className="col-xl-1 col-md-6 mb-4">
                                <Form.Label>Numero</Form.Label>
                                <Form.Control
                                  type="text"
                                  id="numero"
                                  name="numero"
                                  defaultValue={numero}
                                  autoComplete="off"
                                  onChange={(text) => setNumero(text.target.value)}
                                />
                              </Form.Group>
                              <Form.Group className="col-xl-2 col-md-6 mb-4">
                                <Form.Label>complemento</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="complemento"
                                  id="complemento"
                                  defaultValue={complemento}
                                  autoComplete="off"
                                  onChange={(text) => setComplemento(
                                    text.target.value.toUpperCase(),
                                  )}
                                />
                              </Form.Group>

                              <Form.Group className="col-xl-2 col-md-6 mb-4">
                                <Form.Label>Bairro</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="bairro"
                                  id="bairro"
                                  defaultValue={bairro}
                                  autoComplete="off"
                                  onChange={
                                    (text) => setBairro(text.target.value.toUpperCase())
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="col-xl-2 col-md-6 mb-4">
                                <Form.Label>Cidade</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="cidade"
                                  id="cidade"
                                  defaultValue={cidade}
                                  autoComplete="off"
                                  onChange={
                                    (text) => setCidade(text.target.value.toUpperCase())
                                  }
                                />
                              </Form.Group>
                              <Form.Group className="col-xl-2 col-md-6 mb-4">
                                <Form.Label>Estado</Form.Label>
                                <Form.Select
                                  aria-label="Selecione uma opção"
                                  name="estado"
                                  id="estado"
                                  value={estado}
                                  onChange={(text) => setEstado(text.target.value)}
                                >
                                  <option>Selecione uma opção</option>
                                  {Object.entries(estados).map((estado) => (
                                    <option key={estado[1]} value={estado[1]}>
                                      {estado[0]}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </div>

                            <div className="row">
                              <div className="col-xl-2 col-md-6 mb-4">
                                <label htmlFor="inputTelefone">Telefone*</label>
                                <input
                                  type="text"
                                  name="telefoneprincipal"
                                  id="telefoneprincipal"
                                  className="form-control"
                                  placeholder=""
                                  defaultValue={telefoneprincipal}
                                  onChange={(text) => setTelefoneprincipal(text.target.value)}
                                  required
                                  autoComplete="off"
                                />
                              </div>

                              <div className="col-xl-2 col-md-6 mb-4">
                                <label htmlFor="inputPassword4">
                                  Obs Telefone
                                </label>
                                <input
                                  type="text"
                                  name="obstelefoneprincipal"
                                  id="obstelefoneprincipal"
                                  className="form-control"
                                  autoComplete="on"
                                  defaultValue={obstelefoneprincipal}
                                  autoComplete="off"
                                  onChange={(text) => setObstelefoneprincipal(
                                    text.target.value.toUpperCase(),
                                  )}
                                />
                              </div>

                              <div className="col-xl-2 col-md-6 mb-4">
                                <label htmlFor="inputPassword4">
                                  2º Telefone
                                </label>
                                <input
                                  type="text"
                                  name="telefonesegundo"
                                  id="telefonesegundo"
                                  className="form-control"
                                  placeholder=""
                                  defaultValue={telefonesegundo}
                                  autoComplete="off"
                                  onChange={(text) => setTelefonesegundo(text.target.value)}
                                />
                              </div>

                              <div className="col-xl-2 col-md-6 mb-4">
                                <label htmlFor="inputPassword4">
                                  Obs 2º Telefone
                                </label>
                                <input
                                  type="text"
                                  name="obstelefonesegundo"
                                  id="obstelefonesegundo"
                                  className="form-control"
                                  autoComplete="on"
                                  defaultValue={obstelefonesegundo}
                                  autoComplete="off"
                                  onChange={(text) => setObstelefonesegundo(
                                    text.target.value.toUpperCase(),
                                  )}
                                />
                              </div>

                              <div className="col-xl-2 col-md-6 mb-4">
                                <label htmlFor="inputPassword4">
                                  3º Telefone
                                </label>
                                <input
                                  type="text"
                                  name="telefoneoutros"
                                  id="telefoneoutros"
                                  className="form-control"
                                  placeholder=""
                                  defaultValue={telefoneoutros}
                                  autoComplete="off"
                                  onChange={(text) => setTelefoneoutros(text.target.value)}
                                />
                              </div>

                              <div className="col-xl-2 col-md-6 mb-4">
                                <label htmlFor="inputPassword4">
                                  Obs 3º Telefone
                                </label>
                                <input
                                  type="text"
                                  name="obstelefoneoutros"
                                  id="obstelefoneoutros"
                                  className="form-control"
                                  autoComplete="on"
                                  defaultValue={obstelefoneoutros}
                                  autoComplete="off"
                                  onChange={(text) => setObstelefoneoutros(
                                    text.target.value.toUpperCase(),
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <Form.Group
                                className="col-xl-8 col-md-6 mb-4"
                                md="9"
                                controlId="validationFormikObservacao"
                              >
                                <Form.Label>Observação</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={2}
                                  type="text"
                                  name="observacao"
                                  value={observacao}
                                  onChange={(event) => setObservacao(
                                    event.target.value.toUpperCase(),
                                  )}
                                />
                              </Form.Group>

                              <Form.Group
                                className="col-xl-2 col-md-6 mb-4"
                                controlId="situacao"
                              >
                                <Form.Label>
                                  Situação
                                  {' '}
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={(
                                      <Tooltip id="tooltip-select">
                                        <div style={{ fontSize: 'larger' }}>
                                          Situação:
                                          {' '}
                                          <br />
                                        </div>
                                        <div style={{ textAlign: 'left' }}>
                                          {SituacaoTooltip.descricao}
                                        </div>
                                      </Tooltip>
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={faQuestionCircle}
                                      className="ml-1 text-primary"
                                      onClick={handleShow}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </OverlayTrigger>
                                </Form.Label>

                                <OverlayTrigger
                                  placement="right"
                                  overlay={(
                                    <Tooltip id="situacaoTooltip">
                                      <strong>{situacaoInputText}</strong>
                                    </Tooltip>
                                  )}
                                >
                                  <Select
                                    aria-label="Selecione uma opção"
                                    name="situacao"
                                    value={situacaoOptions
                                      .flatMap((group) => group.options)
                                      .find(
                                        (option) => option.value === situacao,
                                      )}
                                    onChange={handleChange}
                                    options={situacaoOptions.flatMap(
                                      (group) => group.options,
                                    )}
                                    isClearable
                                    placeholder="Pesquisar"
                                    styles={customStyles}
                                  />
                                </OverlayTrigger>

                                <Modal show={showModal} onHide={handleClose}>
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      {SituacaoTooltip.titulo}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <div style={{ textAlign: 'left' }}>
                                      <p>{SituacaoTooltip.descricao}</p>
                                      {SituacaoTooltip.mostrarOpcoes
                                        && renderOptions()}
                                    </div>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleClose}
                                    >
                                      Fechar
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </Form.Group>

                              <Form.Group
                                className="col-xl-2 col-md-6 mb-4"
                                controlId="pagamento"
                              >
                                <Form.Label>Pagamento</Form.Label>
                                <Form.Select
                                  aria-label="Selecione uma opção"
                                  name="pagamento"
                                  value={pagamento}
                                  onChange={(e) => {
                                    setPagamento(e.target.value);
                                    setPagamentoInputText(
                                      e.target.options[e.target.selectedIndex]
                                        .text,
                                    );
                                  }}
                                >
                                  <option value="">Selecione uma opção</option>
                                  {Object.entries(paymentStatus).map(
                                    ([code, name]) => (
                                      <option key={code} value={code}>
                                        {name}
                                      </option>
                                    ),
                                  )}
                                </Form.Select>
                              </Form.Group>
                            </div>

                            <div className="row">
                              <div className="col-xl-12 col-md-6 mb-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                >
                                  Salvar dados Atuais
                                </button>
                              </div>
                            </div>
                          </form>
                          {alertError.isVisible && (
                            <div className="fixed-top mt-5">
                              <div className="container">
                                <div className="row justify-content-center">
                                  <div className="col-6">
                                    <Alert
                                      variant="danger"
                                      onClose={() => setAlertError({ isVisible: false })}
                                      dismissible
                                    >
                                      <p className="text-center mt-3">
                                        {alertError.message}
                                      </p>
                                    </Alert>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {alertSuccess.isVisible && (
                            <div className="fixed-top mt-5">
                              <div className="container">
                                <div className="row justify-content-center">
                                  <div className="col-6">
                                    <Alert
                                      variant="success"
                                      onClose={() => setAlertSuccess({ isVisible: false })}
                                      dismissible
                                    >
                                      <p className="text-center mt-3">
                                        {alertSuccess.message}
                                      </p>
                                    </Alert>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Tab>
                        <Tab eventKey="agendamento" title="Agendamento">
                          <Accordion>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                Agendar Atendimento
                              </Accordion.Header>
                              <Accordion.Body>
                                <EditPessoaAgendamento
                                  pessoaId={props.match.params.id}
                                  unidadeId={unidadeId?.toString()}
                                  unidades={unidades}
                                  pessoaBeneficio={beneficio || ''}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          <Accordion>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                Histórico de atendimentos
                              </Accordion.Header>
                              <Accordion.Body>
                                <HistoricoAgendamentoTimeline
                                  atendimentosPresenciais={
                                    atendimentosPresenciais
                                  }
                                  updateUser={getUser}
                                  showFloatAlertSuccess={showFloatAlertSuccess}
                                  unidades={unidades}
                                  pessoaBeneficio={beneficio}
                                  atendentes={atendentes}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Tab>
                        <Tab
                          eventKey="dados-complementares"
                          title="Dados Complementares"
                        >
                          <AtendimentoForm
                            pessoa={pessoa}
                            isAtendimentoEncerrado={false}
                            isDadosComplementares
                            getUser={getUser}
                          />
                        </Tab>
                        <Tab eventKey="etapas" title="Etapas do Processo">
                          <EditPessoaEtapas pessoaId={id} users={atendentes?.atendentes} />
                        </Tab>
                        {situacao === personStatus.DECISAO.DEFERIDO
                          && canAccessContasAReceber(userDepartments) && (
                            <Tab
                              eventKey="recebimento"
                              title="Contas a Receber"
                            >
                              <Recebimento pessoaId={id} />
                            </Tab>
                        )}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Formulário Padrão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Para gerar o formulário padrão é necessário preencher os
                  campos que estão marcados como inválidos.
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleClose}>
                    Fechar
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>

          <ChangeAlert
            dirtyCalledTimes={dirtyCalledTimes}
            showChangeAlert={showChangeAlert}
            setShowChangeAlert={setShowChangeAlert}
          />
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
