import departments from './departments';

const allowedDepartments = [
  departments.WHATSAAP_0800,
  departments.CALLCENTER,
];

const canAccess = (userDepartments) => (
  userDepartments?.some((department) => allowedDepartments.includes(department))
);

export default canAccess;
