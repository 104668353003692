import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { brazillianDate, brazillianTime } from '../../../utils/date';
import EmptyState from '../../Shared/EmptyState';
import RemarcarModal from '../RemarcarModal';
import CancelarModal from '../CancelarModal';
import EditarModal from '../EditarModal';
import ContatoNaoCompareceuModal from '../ContatoNaoCompareceuModal';
import api from '../../../config/configApi';

export default function TabelaAtendimentosPresenciais({
  atendimentosPresenciais, unidades, showFloatAlertSuccess, searchAgendamentos,
}) {
  const [tiposAtendimento, setTiposAtendimento] = useState([]);

  const getTiposAtendimento = async () => {
    const res = await api.get('/tipos-de-atendimento');

    setTiposAtendimento(res.data?.tiposdeatendimento);
  };

  useEffect(() => {
    getTiposAtendimento();
  }, []);

  const [comDataDeAtendimento, setComDataDeAtendimento] = useState(false);
  useEffect(() => {
    const hasDataDeAtendimento = atendimentosPresenciais.some(
      (atendimento) => atendimento.realizadoem,
    );
    setComDataDeAtendimento(hasDataDeAtendimento);
  }, [atendimentosPresenciais]);

  return (
    <div className="table-responsive">
      <div className="card-body">
        <table className="table table-sm table-light w-100">
          <thead>
            <tr>
              <th className="small" hidden>ID</th>
              <th>
                Nome
              </th>
              { comDataDeAtendimento && <th className="small">Data do atendimento</th> }
              <th className="small fw-bold">Data do Agendamento</th>
              <th className="small fw-bold">Benefício/ Tipo de Atendimento</th>
              <th className="small fw-bold">Agendado Por</th>
              <th className="small fw-bold">Unidades</th>
              <th className="small fw-bold">Cadúnico</th>
              <th className="small fw-bold">Observação Pessoa</th>
              <th className="small fw-bold">Assinou Contrato?</th>
              <th className="small fw-bold">Observação Contrato</th>
              <th className="small fw-bold">Ações</th>
            </tr>
          </thead>
          <tbody>
            {
              atendimentosPresenciais?.map((atendimento) => (
                <tr key={atendimento.id}>
                  <td hidden>{atendimento.id}</td>
                  <td className="align-middle small">
                    <a href={`/edit-pessoa/${atendimento.pessoa.id}`} target="_blank" rel="noreferrer">
                      {atendimento.pessoa.nome}
                    </a>
                  </td>
                  { comDataDeAtendimento && (
                    <td className="align-middle small" style={{ borderBottom: '5px solid #dee2e6', padding: '10px' }}>
                        { atendimento.realizadoem && `${brazillianDate(atendimento.realizadoem)} ${brazillianTime(atendimento.realizadoem)}`}
                    </td>
                  )}
                  <td className="align-middle small">
                    {brazillianDate(atendimento.agendadopara)}
                      &nbsp;
                    <br />
                    {brazillianTime(atendimento.agendadopara)}
                  </td>
                  <td className="align-middle small">
                    {atendimento.pessoa?.beneficio}
                    <br />
                    <br />
                    {atendimento.tipoatendimento?.nome || 'Não Informado'}
                  </td>
                  <td className="align-middle small">{atendimento.usuarioqueagendou?.nome}</td>
                  <td className="align-middle small">{atendimento.unidade?.nome}</td>
                  <td className="align-middle small">{atendimento.pessoa?.cadUnico || 'Não Informado'}</td>
                  <td className="align-middle small">{atendimento.pessoa?.observacao?.toUpperCase() || 'Não Informado'}</td>
                  <td className="align-middle small">
                    {{
                      S: 'Sim',
                      N: 'Não',
                    }[atendimento.pessoa?.assinouContrato] || 'Não Definido'}
                  </td>
                  <td className="align-middle small">{atendimento.pessoa?.observacaoContrato || 'Não Informado'}</td>

                  <td className="align-middle">
                    { !atendimento.realizadoem && (
                      <div className="d-flex gap-1 flex-nowrap">
                        <Button href={`/pessoas/${atendimento.pessoa.id}/atendimento/${atendimento.id}`} variant="primary m-1" type="submit">
                          <span>
                            <FontAwesomeIcon icon={faHeadset} />
                          </span>
                            &nbsp;Atender
                        </Button>
                        <RemarcarModal
                          key={atendimento.id}
                          id={atendimento.id}
                          unidadeNome={atendimento.unidade.nome}
                          unidadeId={atendimento.unidade.id}
                          oldDataAgendamento={brazillianDate(atendimento.agendadopara)}
                          oldDataAgendamentoHora={brazillianTime(atendimento.agendadopara)}
                          unidades={unidades}
                          tiposAtendimento={tiposAtendimento}
                          pessoaBeneficio={atendimento.pessoa.beneficio}
                        />
                        <CancelarModal
                          key={atendimento.id}
                          id={atendimento.id}
                          nome={atendimento.pessoa.nome}
                          unidadeNome={atendimento.unidade.nome}
                          unidadeId={atendimento.unidade.id}
                          oldDataAgendamento={brazillianDate(atendimento.agendadopara)}
                          oldDataAgendamentoHora={brazillianTime(atendimento.agendadopara)}
                          unidades={unidades}
                          showFloatAlertSuccess={showFloatAlertSuccess}
                          searchAgendamentos={searchAgendamentos}
                        />
                        <EditarModal
                          key={`editar-${atendimento.id}`}
                          id={atendimento.id}
                          unidadeNome={atendimento.unidade.nome}
                          unidadeId={atendimento.unidade.id}
                          oldDataAgendamento={brazillianDate(atendimento.agendadopara)}
                          oldDataAgendamentoHora={brazillianTime(atendimento.agendadopara)}
                          unidades={unidades}
                          tiposAtendimento={tiposAtendimento}
                          pessoaBeneficio={atendimento.pessoa.beneficio}
                        />
                        { !atendimento.realizadoem && atendimento?.pessoa?.situacaoStatus !== 'AGUARDANDO' && (
                          <ContatoNaoCompareceuModal
                            telefoneprincipal={atendimento.pessoa.telefoneprincipal}
                            telefonesegundo={atendimento.pessoa.telefonesegundo}
                            telefoneoutros={atendimento.pessoa.telefoneoutros}
                            pessoa={atendimento.pessoa.nome}
                            unidade={atendimento.unidade.nome}
                            data={`${brazillianDate(atendimento.agendadopara)} ${brazillianTime(atendimento.agendadopara)}`}
                            beneficio={atendimento.pessoa.beneficio}
                          />
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <EmptyState isActive={!atendimentosPresenciais.length} />
      </div>
    </div>
  );
}

TabelaAtendimentosPresenciais.defaultProps = {
  atendimentosPresenciais: [],
  searchAgendamentos: () => {},
};

TabelaAtendimentosPresenciais.propTypes = {
  atendimentosPresenciais: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nome: PropTypes.string,
      agendadopara: PropTypes.string,
      cancelado: PropTypes.bool,
      realizadoem: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      usuarioqueagendouId: PropTypes.number,
      usuarioqueatendeuId: PropTypes.number,
      pessoaId: PropTypes.number,
      tipodeatendimentoId: PropTypes.number,
      unidadeId: PropTypes.number,
      usuarioqueagendou: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      usuarioqueatendeu: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      pessoa: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
          beneficio: PropTypes.string,
        }),
      ),
      unidade: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
      tipodeatendimento: PropTypes.objectOf(
        PropTypes.shape({
          nome: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
    }),
  ),
  unidades: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    nome: PropTypes.string.isRequired,
  }).isRequired,
  showFloatAlertSuccess: PropTypes.func.isRequired,
  searchAgendamentos: PropTypes.func,
};
