import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, Form, Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import api from '../../config/configApi';
import canAccessCancelar from '../../utils/acl/cancelar';
import getuUserDepartments from '../../utils/local-storage';

export default function CancelarModal(
  {
    id,
    unidadeId,
    unidadeNome,
    oldDataAgendamento,
    oldDataAgendamentoHora,
    nome,
    updateUser,
    showFloatAlertSuccess,
    searchAgendamentos,
  },
) {
  const [cancelamento] = useState({
    unidadeId,
    unidadeNome,
  });
  const [userDepartments] = useState(getuUserDepartments());
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [isOpen, setIsOpen] = useState(false);

  const handleFechar = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCancelar = async () => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      await api.patch(`/atendimentos-presenciais/${id}/cancelar`, headers);
      showFloatAlertSuccess('Cancelamento realizado com sucesso!');
      searchAgendamentos();
      updateUser();
    } catch (error) {
      setAlertError({ isVisible: true, message: error.response.data.mensagem || 'Não foi possível cancelar este atendimento' });
    }
  };
  const perfilMaster = '99';

  return (
    <>
      {(canAccessCancelar(userDepartments) || perfilMaster === localStorage.getItem('perfilId')) && (
      <Button variant="danger m-1" type="button" onClick={handleOpenModal}>
        <span>
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
          &nbsp;Cancelar
      </Button>
      )}
      <Modal show={isOpen} onHide={handleFechar} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Cancelar Atendimento</Modal.Title>
        </Modal.Header>
        <Alert className="text-center m-4" variant="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} size="6x" />
          <h2>
            Tem certeza que deseja cancelar o Atendimento?
          </h2>
          <strong>{nome}</strong>
          { cancelamento.unidadeId
            ? (
              <>
                <br />
                <p>
                  <u>
                    {oldDataAgendamento}
                  </u>
                  <strong>{' às '}</strong>
                  <u>
                    {oldDataAgendamentoHora}
                  </u>
                  <strong>{ ' na unidade ' }</strong>
                  <u>
                    {unidadeNome}
                  </u>
                  <br />
                </p>
              </>
            ) : ''}

        </Alert>
        <Modal.Body>
          <Form id="form-cancelar-agendamento">
            { alertError.isVisible
              && (
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <Alert variant="danger" onClose={() => setAlertError({ isVisible: false })} dismissible>
                        <p className="text-center mt-3">
                          {alertError.message}
                        </p>
                      </Alert>
                    </div>
                  </div>
                </div>
              )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCancelar}>
            Cancelar
          </Button>
          <Button variant="secondary" onClick={handleFechar}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

CancelarModal.defaultProps = {
  searchAgendamentos: () => {},
  updateUser: () => {},
};

CancelarModal.propTypes = {
  id: PropTypes.number.isRequired,
  unidadeId: PropTypes.number.isRequired,
  unidadeNome: PropTypes.string.isRequired,
  oldDataAgendamento: PropTypes.string.isRequired,
  oldDataAgendamentoHora: PropTypes.string.isRequired,
  nome: PropTypes.string.isRequired,
  updateUser: PropTypes.func,
  showFloatAlertSuccess: PropTypes.func.isRequired,
  searchAgendamentos: PropTypes.func,
};
