import JanelasManager from '../janelas-manager';
import RegrasDaAgendaPorUnidade from './index';
import {
  formatarDataParaDiaDaSemana,
  getWindowTime,
} from '../../../utils/date';

const getAgendaByDiaSemana = (nomeUnidade, agendadopara, pessoGrupoBeneficio) => {
  const ruleGroup = RegrasDaAgendaPorUnidade[nomeUnidade]?.[pessoGrupoBeneficio];
  const diaDaSemana = formatarDataParaDiaDaSemana(
    agendadopara.slice(0, 10),
  );
  return ruleGroup?.[diaDaSemana] || ruleGroup?.['all-days'];
};

const countQuantidadeAgendados = (atendimentos, janelasDeAtendimento, pessoaGrupoBeneficio) => {
  const janelaDeTempoComAgendados = Object.fromEntries(
    janelasDeAtendimento.map((key) => [key, { agendados: 0 }]),
  );

  return atendimentos.reduce((acc, atendimento) => {
    const atendimentoGrupoBeneficio = atendimento.pessoa?.beneficio?.toLowerCase().startsWith('loas')
      ? 'LOAS'
      : 'PREVIDENCIÁRIO';

    if (pessoaGrupoBeneficio !== atendimentoGrupoBeneficio) return acc;

    const atendimentoWindowTime = getWindowTime(atendimento.agendadopara);

    if (janelasDeAtendimento.includes(atendimentoWindowTime)) {
      acc[atendimentoWindowTime].agendados += 1;
    }

    return acc;
  }, janelaDeTempoComAgendados);
};

const countQuantidadeVagas = (quantidadeAgendados, regrasAgendaByDiaSemana) => (
  Object.entries(quantidadeAgendados).reduce((acc, [janela, dados]) => {
    const regrasAgendaByHour = regrasAgendaByDiaSemana?.[janela] || regrasAgendaByDiaSemana?.['all-hours'];

    if (regrasAgendaByHour === undefined) {
      acc[janela] = { ...dados, vagas: 'SEM LIMITE' };
    } else {
      if (regrasAgendaByHour <= dados.agendados) {
        delete quantidadeAgendados[janela];
        return acc;
      }
      acc[janela] = { ...dados, vagas: regrasAgendaByHour - dados.agendados };
    }

    return acc;
  }, {})
);

export default function checkWindowTimeAvailability(
  unidades,
  atendimentos,
  unidadeSelected,
  pessoaGrupoBeneficio,
  agendadoPara,
) {
  try {
    const nomeSelectedUnidade = unidades.find(
      (u) => u.id === Number(unidadeSelected),
    )?.nome;
    if (!nomeSelectedUnidade) {
      return {};
    }
    const janelasDeAtendimentoDaUnidade = JanelasManager.getJanelasByUnidade(
      nomeSelectedUnidade,
    );
    if (!janelasDeAtendimentoDaUnidade) {
      return {};
    }
    const regrasAgendaByDiaSemana = getAgendaByDiaSemana(
      nomeSelectedUnidade,
      agendadoPara,
      pessoaGrupoBeneficio,
    );

    const quantidadeDeAgendados = countQuantidadeAgendados(
      atendimentos,
      janelasDeAtendimentoDaUnidade,
      pessoaGrupoBeneficio,
    );

    return countQuantidadeVagas(quantidadeDeAgendados, regrasAgendaByDiaSemana);
  } catch (error) {
    return {};
  }
}
