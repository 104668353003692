import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';
import api from '../../../../config/configApi';

export default function FechamentoIndicacao({
  startDate, endDate, unidadeId, origemdasligacoes,
}) {
  const [ligacoes, setLigacoes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertError, setAlertError] = useState({ isVisible: false, message: '' });
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      try {
        const origemQuery = origemdasligacoes ? `&origemdasligacoes=${origemdasligacoes}` : '';
        const unidadeQuery = unidadeId ? `&unidadeId=${unidadeId}` : '';
        const { data } = await api.get(`/atendimentos-presenciais/relatorio-fechamento/fechamento-indicacao?dataInicio=${startDate.toLocaleDateString('pt-BR').split('/').reverse().join('-') || ''}&dataFim=${endDate.toLocaleDateString('pt-BR').split('/').reverse().join('-') || ''}${unidadeQuery}${origemQuery}`, {}, headers);
        setLigacoes(data?.report?.[0] || []);
      } catch (err) {
        setLigacoes([]);
        setAlertError({
          isVisible: true,
          message: err.message || 'Não foi possível carregar os dados. Tente novamente.',
        });
        setTimeout(() => {
          setAlertError({ isVisible: false, message: '' });
        }, 5000);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, unidadeId, origemdasligacoes]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center my-3">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      </div>
    );
  }
  if (alertError.isVisible) return <p>{alertError.message}</p>;

  if (!ligacoes) return <p>Nenhum dado disponível.</p>;

  return (
    <div className="card shadow-lg p-4">
      <h4 className="mb-3 d-flex gap-3 align-items-center text-primary">
        Fechamento - &nbsp;
        Indicação
      </h4>

      <div className="d-flex flex-column gap-2">
        <div className="d-flex">

          <p className="d-flex flex-column gap-2 move-left-padding">
            <strong>Período: </strong>
            {format(startDate, 'dd/MM/yyyy', { locale: ptBR })}
            &nbsp;até&nbsp;
            {format(endDate, 'dd/MM/yyyy', { locale: ptBR })}
          </p>
        </div>
        <div className="d-flex">
          <p className="fw-bold mt-n3">
            Total:&ensp;
            {ligacoes?.countPessoasCadastradasBySituacao?.reduce(
              (acc, item) => acc + item.count,
              0,
            )}
          </p>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-start align-items-md-baseline">
          <h5 className="text-primary">Situação</h5>
        </div>
        {ligacoes?.countPessoasCadastradasBySituacao?.length > 0 ? (
          ligacoes?.countPessoasCadastradasBySituacao?.map((situacao) => (
            <p className="mb-0">
              {situacao.situacaoStatus || 'O campo não foi preenchido'}
              :
              {' '}
              <span className="badge bg-light text-dark ms-2">{situacao.count}</span>
            </p>
          ))
        ) : (
          <p className="text-muted">Nenhuma situação encontrada.</p>
        )}
        <p className="text-muted ms-md--5 text-md-start" style={{ fontSize: '0.8rem' }}>
          (Pessoas com origem 0800 que foram cadastradas
          na datadasligacoes/unidade do filtro agrupadas por Situação.)
        </p>
      </div>
      <div className="d-flex flex-column flex-md-row align-items-center align-items-md-baseline">
        <h5 className="text-primary mt-4">Tipos de Benefícios</h5>
      </div>
      {ligacoes?.countPessoasCadastradasByBeneficio?.length > 0 ? (
        ligacoes?.countPessoasCadastradasByBeneficio?.map((beneficio) => (
          <p className="mb-0">
            {beneficio.beneficio || 'O campo não foi preenchido'}
            {' '}
            :
            <span className="badge bg-light text-dark ms-2">{beneficio.count}</span>
          </p>
        ))
      ) : (
        <p className="text-muted">Nenhum benefício encontrado.</p>
      )}
      <p className="text-muted ms-md--5 text-md-start" style={{ fontSize: '0.8rem' }}>
        (Pessoas com origem 0800 que foram cadastradas
        na datadasligacoes/unidade do filtro
        agrupadas por Beneficio.)
      </p>
      <div className="d-flex flex-column flex-md-row align-items-center align-items-md-baseline">
        <h5 className="text-primary mt-4">Indicação\ Observação da Indicação</h5>
      </div>
      {ligacoes?.countPessoasCadastradasByObservacaoIndicacao?.length > 0 ? (
        ligacoes?.countPessoasCadastradasByObservacaoIndicacao?.map((indicacao) => (
          <p className="mb-0">
            {indicacao.indicacao || 'O campo não foi preenchido'}
            &nbsp;-&nbsp;
            {indicacao.observacaoindicacao}
            <span className="badge bg-light text-dark ms-2">{indicacao.count}</span>
          </p>
        ))
      ) : (
        <p className="text-muted">Nenhuma observação encontrada.</p>
      )}
      <p className="text-muted ms-md--5 text-md-start" style={{ fontSize: '0.8rem' }}>
        (Pessoas com origem 0800 que foram cadastradas
        na datadasligacoes/unidade do filtro agrupadas
        [Indicação & observação da Indicação].)
      </p>
    </div>
  );
}

FechamentoIndicacao.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  unidadeId: PropTypes.number.isRequired,
  origemdasligacoes: PropTypes.string.isRequired,
};
