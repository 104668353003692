import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';
import {
  startOfMonth, endOfMonth, addDays, startOfWeek, startOfDay, endOfDay,
} from 'date-fns';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Navbar } from '../../../../components/Navbar';
import { Sidebar } from '../../../../components/Sidebar';
import LigacoesTable from './ligacoesTable';
import RelatorioFechamento from './RelatorioFechamento';
import RelatorioContratosAssinados from './RelatorioContratosAssinados';
import FechamentoIndicacao from './FechamentoIndicacao';

import api from '../../../../config/configApi';
import 'bootstrap/dist/css/bootstrap.min.css';

registerLocale('pt-BR', ptBr);

export default function Relatorios() {
  const [relatorio, setRelatorio] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [buttonsDateFilter, setButtonsDateFilter] = useState({
    hoje: false,
    essaSemana: false,
    esseMes: false,
  });
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [unidades, setUnidades] = useState([]);
  const [unidadeNome, setUnidadeNome] = useState(null);
  const [unidadesOptions, setUnidadesOptions] = useState([]);
  const [unidadeId, setUnidadeId] = useState(null);
  const [origemdasligacoes, setOrigemDasLigacoes] = useState('');
  const [origens, setOrigens] = useState([]);

  const datePickerEndDate = useRef(null);
  const relatorioSelectRef = useRef(null);

  const getUnidades = async () => {
    try {
      const { data } = await api.get('/unidade/unidades');
      setUnidades(data.unidades);
      setUnidadesOptions(
        <>
          {data.unidades.map((unidade) => (
            <option value={unidade.id}>{unidade.nome}</option>
          ))}
        </>,
      );
    } catch (error) {
      alert('Occorreu um erro ao recuperar as unidades');
    }
  };

  const downloadRelatorioFechamento = async () => {
    const headers = {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    try {
      const unidadeQuery = unidadeId ? `&unidadeId=${unidadeId}` : '';
      const response = await api.get(`/atendimentos-presenciais/relatorio-fechamento?dataInicio=${new Date(startDate)?.toISOString()?.substring(0, 10)}&dataFim=${new Date(endDate)?.toISOString()?.substring(0, 10)}${unidadeQuery}`, { headers, responseType: 'blob' });

      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'relatorio-fechamento.xlsx';

      if (contentDisposition) {
        console.log('contentDisposition:', contentDisposition);
        const [, matchedFileName] = contentDisposition.match(/attachment; filename=(.*?\.xlsx)/) || [];
        console.log('matchedFileName:', matchedFileName);
        if (matchedFileName) {
          fileName = matchedFileName;
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      alert('Não foi possível carregar o relatório. Por favor, tente novamente ou entre em contato com o suporte.');
    }
  };

  const disableFilters = () => {
    setIsFilterDisabled(true);
    setStartDate(null);
    setEndDate(null);
  };

  const enableFilters = () => {
    setIsFilterDisabled(false);
  };

  useEffect(() => {
    if (relatorioSelectRef.current?.value === 'painel-de-ligacoes' || relatorioSelectRef.current?.value === 'painel-de-contratos' || relatorioSelectRef.current?.value === 'painel-de-chamados') {
      disableFilters();
    } else {
      enableFilters();
    }
    getUnidades();
  }, [relatorio]);

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const getOrigens = async () => {
    await api
      .get('/origem/origens')
      .then((response) => {
        setOrigens(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getOrigens();
  }, []);

  return (
    <div id="wrapper">
      <Sidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Navbar />
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">Relatórios</h1>
            </div>
            <hr />
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <Form className="form">
                  <div className="row d-flex align-items-end">
                    <div className="col-lg-7 col-md-12">
                      <div className="row d-flex align-items-end">
                        <select
                          className="form-select col-sm-12 col-md-4 col-lx-1 custom-select my-1 mr-sm-2 my-sm-2"
                          name="origemdasligacoes"
                          id="origemdasligacoes"
                          placeholder="origemdasligacoes"
                          value={relatorio}
                          onChange={(text) => setRelatorio(text.target.value)}
                          ref={relatorioSelectRef}
                        >
                          <option value="">Escolha um tipo</option>
                          <option
                            key="ligacoes"
                            value="ligacoes"
                            selected={relatorio === 'ligacoes'}
                          >
                            Ligações
                          </option>

                          <option
                            key="relatorio-contratos-assinados"
                            value="relatorio-contratos-assinados"
                            selected={relatorio === 'relatorio-contratos-assinados'}
                          >
                            Relatório de Contratos Assinados
                          </option>

                          <option
                            key="fechamento-diario-indicacao"
                            value="fechamento-diario-indicacao"
                            selected={relatorio === 'fechamento-diario-indicacao'}
                          >
                            Fechamento Diário por Indicação
                          </option>

                          <option
                            key="relatorio-fechamento"
                            value="relatorio-fechamento"
                            selected={relatorio === 'relatorio-fechamento'}
                          >
                            Relatório de Fechamento
                          </option>
                          <option
                            key="painel-de-ligacoes"
                            value="painel-de-ligacoes"
                            selected={relatorio === 'painel-de-ligacoes'}
                          >
                            Painel de Ligações
                          </option>
                          <option
                            key="painel-de-contratos"
                            value="painel-de-contratos"
                            selected={relatorio === 'painel-de-contratos'}
                          >
                            Painel de Contratos
                          </option>
                          <option
                            key="painel-de-chamados"
                            value="painel-de-chamados"
                            selected={relatorio === 'painel-de-chamados'}
                          >
                            Painel de Chamados
                          </option>
                        </select>
                        {(relatorioSelectRef.current?.value === 'relatorio-contratos-assinados' || relatorioSelectRef.current?.value === 'relatorio-contratos-assinados') && (
                          <div className="col-sm-12 col-md-3 mr-sm-2 my-sm-1 mt-n2">
                            <select
                              className="custom-select my-1 mr-sm-2"
                              name="unidade"
                              id="unidade"
                              placeholder="unidade"
                              onChange={(e) => {
                                const { selectedIndex } = e.target;
                                setUnidadeId(e.target.value);
                                setUnidadeNome(
                                  e.target.options[selectedIndex].text,
                                );
                              }}
                            >
                              <option value="">Todas Unidades</option>
                              {unidadesOptions}
                            </select>
                          </div>
                        )}
                        {(relatorioSelectRef.current?.value === 'fechamento-diario-indicacao' || relatorioSelectRef.current?.value === 'fechamento-diario-indicacao') && (
                          <div className="col-sm-12 col-md-3 mr-sm-2 my-sm-1 mt-n2">
                            <select
                              className="custom-select my-1 mr-sm-2"
                              name="unidade"
                              id="unidade"
                              placeholder="unidade"
                              onChange={(e) => {
                                const { selectedIndex } = e.target;
                                setUnidadeId(e.target.value);
                                setUnidadeNome(
                                  e.target.options[selectedIndex].text,
                                );
                              }}
                            >
                              <option value="">Todas Unidades</option>
                              {unidadesOptions}
                            </select>
                          </div>
                        )}
                        {(relatorioSelectRef.current?.value === 'relatorio-fechamento' || relatorioSelectRef.current?.value === 'fechamento-diario-indicacao') && (
                          <div className="col-sm-12 col-md-4 col-xl-4 mr-sm-2 my-sm-2 ml-n2">
                            <Select
                              id="origemdasligacoesSelect"
                              isMulti
                              options={origens?.origens?.map((item) => ({
                                value: item.nome,
                                label: item.nome,
                              }))}
                              value={origens?.origens
                                ?.filter((item) => origemdasligacoes.includes(item.nome))
                                .map((item) => ({
                                  value: item.nome,
                                  label: item.nome,
                                }))}
                              onChange={(selectedOptions) => {
                                setOrigemDasLigacoes(selectedOptions
                                  ? selectedOptions.map((option) => option.value)
                                  : []);
                              }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              placeholder="Selecione a Origem"
                              required
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="row d-flex align-items-end">
                        <div className="col-sm-12 col-md-3 col-xl-3 p-1 m-0 pb-2">
                          <label htmlFor="startDate">de:&nbsp;</label>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              datePickerEndDate.current.setOpen(true);

                              setButtonsDateFilter({
                                hoje: false,
                                essaSemana: false,
                                esseMes: false,
                              });
                            }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd-MM-yyyy"
                            locale="pt-BR"
                            id="startDate"
                            className="form-control form-control-sm"
                            disabled={isFilterDisabled}
                          />
                        </div>
                        <div className="col-sm-12 col-md-3 col-xl-3 p-1 m-0 pb-2">
                          <label htmlFor="endDate">até:&nbsp;</label>
                          <DatePicker
                            selected={endDate}
                            ref={datePickerEndDate}
                            onChange={(date) => {
                              setEndDate(date);
                              setButtonsDateFilter({
                                hoje: false,
                                essaSemana: false,
                                esseMes: false,
                              });
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={setEndDate}
                            minDate={startDate}
                            maxDate={addDays(
                              new Date(startDate || new Date()),
                              31,
                            )}
                            dateFormat="dd-MM-yyyy"
                            locale="pt-BR"
                            className="form-control form-control-sm"
                            id="endDate"
                            disabled={isFilterDisabled}
                          />
                        </div>
                        <div className="col-sm-12 col-xl-6 p-1 m-0 pb-2 d-flex align-items-end">
                          <button
                            type="button"
                            className={`btn btn btn-sm btn-outline-dark mr-1 ${
                              buttonsDateFilter.hoje ? 'active' : ''
                            }`}
                            onClick={() => {
                              setButtonsDateFilter({
                                hoje: !buttonsDateFilter.hoje,
                                essaSemana: false,
                                esseMes: false,
                              });

                              const startOfDayDate = startOfDay(new Date());
                              const endOfDayDate = endOfDay(new Date());
                              setStartDate(startOfDayDate);
                              setEndDate(endOfDayDate);
                            }}
                            disabled={isFilterDisabled}
                          >
                            Hoje
                          </button>
                          <button
                            type="button"
                            className={`btn btn btn-sm btn-outline-dark mr-1 ${
                              buttonsDateFilter.essaSemana ? 'active' : ''
                            }`}
                            onClick={() => {
                              setButtonsDateFilter({
                                hoje: false,
                                essaSemana: !buttonsDateFilter.essaSemana,
                                esseMes: false,
                              });

                              const startOfWeekDate = startOfWeek(new Date(), {
                                weekStartsOn: 1,
                              });
                              setStartDate(startOfWeekDate);
                              setEndDate(addDays(startOfWeekDate, 4));
                            }}
                            disabled={isFilterDisabled}
                          >
                            Essa semana
                          </button>
                          <button
                            type="button"
                            className={`btn btn btn-sm btn-outline-dark ${
                              buttonsDateFilter.esseMes ? 'active' : ''
                            }`}
                            onClick={() => {
                              setButtonsDateFilter({
                                hoje: false,
                                essaSemana: false,
                                esseMes: !buttonsDateFilter.esseMes,
                              });
                              setStartDate(startOfMonth(new Date()));
                              setEndDate(endOfMonth(new Date()));
                            }}
                            disabled={isFilterDisabled}
                          >
                            Esse mês
                          </button>
                          {relatorioSelectRef.current?.value === 'relatorio-fechamento' && startDate && endDate && (
                            <Dropdown>
                              <Dropdown.Toggle variant="secondary" id="download">
                                <FontAwesomeIcon icon={faFile} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/baixar-relatorio" onClick={downloadRelatorioFechamento}>
                                  <FontAwesomeIcon icon={faFileExcel} />
                                  Baixar Relatório
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="card-body">
                {relatorioSelectRef.current?.value === 'ligacoes'
                  && startDate
                  && endDate && (
                    <LigacoesTable startDate={startDate} endDate={endDate} />
                )}
                {relatorioSelectRef.current?.value === 'relatorio-fechamento'
                  && startDate
                  && endDate && (
                    <RelatorioFechamento
                      startDate={startDate}
                      endDate={endDate}
                      unidadeId={unidadeId}
                      unidadeNome={unidadeNome}
                    />
                )}
                {relatorioSelectRef.current?.value === 'relatorio-contratos-assinados' && startDate && endDate && (
                  <RelatorioContratosAssinados
                    startDate={startDate}
                    endDate={endDate}
                    unidadeId={unidadeId}
                    unidadeNome={unidadeNome}
                  />
                )}
                {relatorioSelectRef.current?.value === 'fechamento-diario-indicacao' && startDate && endDate && (
                  <FechamentoIndicacao
                    startDate={startDate}
                    endDate={endDate}
                    unidadeId={unidadeId}
                    unidadeNome={unidadeNome}
                    origemdasligacoes={origemdasligacoes}
                  />
                )}
                {
                  (!relatorioSelectRef.current?.value
                    || !startDate
                    || !endDate)
                    && relatorioSelectRef.current?.value !== 'painel-de-ligacoes'
                    && relatorioSelectRef.current?.value !== 'painel-de-contratos'
                    && relatorioSelectRef.current?.value !== 'painel-de-chamados'
                     && (
                     <div className="empty-state m-5">
                       <div className="empty-state-icon text-center">
                         <i className="fas fa-exclamation-triangle fa-2x" />
                       </div>
                       <h2 className="text-center">Filtros não preenchidos</h2>
                       <p className="text-center">
                         Para solicitar um relatório é necessário selecionar o
                         tipo e intervalo de datas.
                       </p>
                     </div>
                     )
                }

                {relatorioSelectRef.current?.value === 'painel-de-ligacoes' && (
                  <div className="card-body">
                    <div className="iframe-container">
                      <iframe
                        title="Painel de Ligações"
                        src="https://app.powerbi.com/view?r=eyJrIjoiODVkMDNkNjMtNDE1MS00OGY3LWJiODAtNmNjNThlMzdlNGY3IiwidCI6ImU2Y2RlMmY2LTIwNmUtNGM0ZS1iYzUyLTVjMDcwOGJkYTcxMiJ9"
                        width="100%"
                        height="720px"
                        frameBorder="0"
                      >
                        {' '}
                      </iframe>
                    </div>
                  </div>
                )}

                {relatorioSelectRef.current?.value
                  === 'painel-de-contratos' && (
                  <div className="card-body">
                    <div className="iframe-container">
                      <iframe
                        title="Painel de Contratos"
                        src="https://app.powerbi.com/view?r=eyJrIjoiZTFiZDI4YjEtYTUxNy00ZGNkLTkyZDAtNzNjNGJmYTg4MmRiIiwidCI6ImU2Y2RlMmY2LTIwNmUtNGM0ZS1iYzUyLTVjMDcwOGJkYTcxMiJ9"
                        width="100%"
                        height="720px"
                        frameBorder="0"
                      >
                        {' '}
                      </iframe>
                    </div>
                  </div>
                )}

                {relatorioSelectRef.current?.value === 'painel-de-chamados' && (
                <div className="card-body">
                  <div className="iframe-container">
                    <iframe
                      title="Painel de Chamados"
                      src="https://app.powerbi.com/view?r=eyJrIjoiMmY4NDMxZDQtMzFiZS00NjhhLThhZDgtN2YxYzQ5YmUzYzJhIiwidCI6ImU2Y2RlMmY2LTIwNmUtNGM0ZS1iYzUyLTVjMDcwOGJkYTcxMiJ9"
                      width="100%"
                      height="720px"
                      frameBorder="0"
                    >
                      {' '}
                    </iframe>
                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
