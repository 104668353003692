import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Spinner } from 'react-bootstrap';

import api from '../../../../config/configApi';

export default function RelatorioContratosAssinados({
  startDate,
  endDate,
  unidadeId,
  unidadeNome,
}) {
  const [loading, setLoading] = useState(true);
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [relatorio, setRelatorio] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      try {
        const unidadeQuery = unidadeId ? `&unidadeId=${unidadeId}` : '';
        const { data } = await api.get(`/atendimentos-presenciais/relatorio-fechamento/fechamento-assinaram-contrato?dataInicio=${startDate.toLocaleDateString('pt-BR').split('/').reverse().join('-') || ''}&dataFim=${endDate.toLocaleDateString('pt-BR').split('/').reverse().join('-') || ''}${unidadeQuery}`, {}, headers);

        setRelatorio(data?.report?.[0]);
        console.log(data?.report?.[0]);
      } catch (err) {
        setRelatorio(null);
        setAlertError({
          isVisible: true,
          message: err.message || 'Não foi possível carregar o relatório. Por favor, tente novamente ou entre em contato com o suporte.',
        });
        setTimeout(() => {
          setAlertError({ isVisible: false, message: '' });
        }, 5000);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, unidadeId]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center my-3">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      </div>
    );
  }

  if (alertError.isVisible) return <p>{alertError.message}</p>;

  if (!relatorio) return <p>Nenhum dado disponível.</p>;

  return (
    <div className="card shadow-lg p-4">
      <h4 className="mb-3 d-flex gap-3 align-items-center text-primary">
        Fechamento - &nbsp;
        {unidadeNome}
      </h4>

      <div className="d-flex flex-column gap-2">
        <div className="d-flex">

          <p className="d-flex flex-column gap-1 move-left-padding mb-1">
            <strong>Período: </strong>
            {format(startDate, 'dd/MM/yyyy', { locale: ptBR })}
            &nbsp;até&nbsp;
            {format(endDate, 'dd/MM/yyyy', { locale: ptBR })}
          </p>
        </div>

        <div style={{ marginLeft: '1px', marginRight: '30px' }}>
          <strong>Vieram:</strong>
          &nbsp;
          {relatorio.countAtendimentos}
          <br />
          <br />
          <strong>Não Vieram:</strong>
          &nbsp;
          {relatorio.countAtendimentosNaoRealizados}
          <br />
          <br />
          <strong>Assinaram:</strong>
          &nbsp;
          {relatorio.countAssinaramContrato}
          <br />
          <br />
          <strong>Não Assinaram:</strong>
          &nbsp;
          {relatorio.countNaoAssinaramContrato}
          <br />
          <br />
          <strong>Com CRAS:</strong>
          &nbsp;
          {relatorio.countPessoasComCras}
          <small className="text-muted ms-2">
            (Contagem de pessoas que foram atendidas
            na data/unidade do filtro e que assinaram
            o contrato na data do filtro.)
          </small>
          <br />
          <br />
          <strong>Sem CRAS:</strong>
          &nbsp;
          {relatorio.countPessoasSemCras}
          <small className="text-muted ms-2">
            (Contagem de pessoas que foram atendidas
            na data/unidade do filtro e que assinaram
            o contrato na data do filtro.)
          </small>
        </div>
        <br />
      </div>
      <h5 className="text-primary mt-4">
        Tipos de Benefícios
      </h5>
      {relatorio?.countPessoasAssinaramByBeneficio?.length > 0 ? (
        relatorio?.countPessoasAssinaramByBeneficio?.map((beneficio) => (
          <p key={beneficio.beneficio} className="mb-1">
            {beneficio.beneficio || 'O campo não foi preenchido'}
            :
            <span className="badge bg-light text-dark ms-2">{beneficio.count}</span>
          </p>
        ))
      ) : (
        <p className="text-muted">Nenhum benefício encontrado.</p>
      )}
      <span className="text-muted ms--5" style={{ fontSize: '0.8em' }}>
        (Pessoas que foram atendidas na data/unidade do
        filtro e que assinaram o contrato na data do filtro agrupadas por Benefício.)
      </span>
      <br />
      <h5 className="text-primary">
        Motivos para Não Assinatura
      </h5>
      {relatorio?.countObservacaoNaoAssinouContrato?.length > 0 ? (
        relatorio?.countObservacaoNaoAssinouContrato?.map((obs) => (
          <p key={obs.observacaoContrato} className="mb-1">
            {obs.observacaoContrato || 'O campo não foi preenchido'}
            :
            <span className="badge bg-light text-dark ms-2">{obs.count}</span>
          </p>
        ))
      ) : (
        <p className="text-muted">Nenhum motivo registrado.</p>
      )}
      <span className="text-muted ms--5" style={{ fontSize: '0.8em' }}>
        (Pessoas que foram atendidas na data/unidade do filtro e que não assinaram
        o contrato na data do filtro agrupadas por Indicação.)
      </span>
      <br />

      <h5 className="text-primary">
        Indicações
      </h5>

      {relatorio?.countPessoasAssinaramByIndicacao?.length > 0 ? (
        relatorio?.countPessoasAssinaramByIndicacao?.map((indicacao) => (
          <p key={indicacao.indicacao} className="mb-1">
            {indicacao.indicacao || 'O campo não foi preenchido'}
            :
            &nbsp;
            <span className="badge bg-light text-dark ms-2">{indicacao.count}</span>
          </p>
        ))
      ) : (
        <p className="text-muted">Nenhuma indicação registrada.</p>
      )}
      <span className="text-muted ms--5" style={{ fontSize: '0.8em' }}>
        (Pessoas que foram atendidas na data/unidade do
        filtro e que assinaram o contrato
        na data do filtro agrupadas por Indicação.)
      </span>
      <br />
      <footer className="mt-4 text-muted">
        Para gerar este relatório, foram considerados as seguintes informações:
        <ul>
          <li>
            Data que a pessoa assinou o contrato (dados complementares).
          </li>
          <li>
            Data em que um atendimento foi realizado.
          </li>
        </ul>
      </footer>
    </div>
  );
}

RelatorioContratosAssinados.propTypes = {
  startDate: PropTypes.isRequired,
  endDate: PropTypes.isRequired,
  unidadeId: PropTypes.number.isRequired,
  unidadeNome: PropTypes.string.isRequired,
};
