import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const brTimezone = 'America/Sao_Paulo';

function brazillianDate(date) {
  const utcDate = new Date(date);
  const options = {
    day: '2-digit', month: '2-digit', year: 'numeric', timeZone: brTimezone,
  };
  return utcDate.toLocaleDateString('pt-BR', options);
}

function brazillianTime(date) {
  const utcDate = new Date(date);
  const options = {
    hour: '2-digit', minute: '2-digit', timeZone: brTimezone,
  };
  return utcDate.toLocaleTimeString('pt-BR', options);
}

function brazillianDateTime(dateTime) {
  const utcDate = new Date(dateTime);
  const options = {
    day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: brTimezone,
  };
  return utcDate.toLocaleTimeString('pt-BR', options);
}

function getWindowTime(agendadopara) {
  const horario = brazillianTime(agendadopara);
  const hour = horario.slice(0, 2);
  const tensOfTheMinute = horario.slice(3, 4);
  let windowTime;
  if (tensOfTheMinute >= 3) {
    windowTime = `${hour}:30`;
  } else {
    windowTime = `${hour}:00`;
  }

  return windowTime;
}

const formatarDataParaDiaDaSemana = (data) => {
  const [year, month, day] = data.split('-');
  return format(new Date(year, month - 1, day), 'EEE', { locale: ptBR });
};

export {
  brazillianDate,
  brazillianTime,
  brazillianDateTime,
  getWindowTime,
  formatarDataParaDiaDaSemana,
};
