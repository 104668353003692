export default {
  CENTRO: {
    LOAS: {
      'all-days': {
        'all-hours': 18,
      },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
    PREVIDENCIÁRIO: {
      segunda: { 'all-hours': 2 },
      terça: { 'all-hours': 2 },
      quarta: { 'all-hours': 4 },
      quinta: { 'all-hours': 2 },
      sexta: { 'all-hours': 2 },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
  },
  ALCÂNTARA: {
    LOAS: {
      'all-days': {
        'all-hours': 8,
      },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
    PREVIDENCIÁRIO: {
      segunda: { 'all-hours': 1 },
      terça: { 'all-hours': 1 },
      quarta: { 'all-hours': 1 },
      quinta: { 'all-hours': 1 },
      sexta: { 'all-hours': 0 },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
  },
  TAQUARA: {
    LOAS: {
      'all-days': {
        'all-hours': 12,
      },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
    PREVIDENCIÁRIO: {
      segunda: { 'all-hours': 2 },
      terça: { 'all-hours': 2 },
      quarta: { 'all-hours': 2 },
      quinta: { 'all-hours': 1 },
      sexta: { 'all-hours': 0 },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
  },
  NILÓPOLIS: {
    LOAS: {
      'all-days': {
        'all-hours': 8,
      },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
    PREVIDENCIÁRIO: {
      segunda: { 'all-hours': 2 },
      terça: { 'all-hours': 2 },
      quarta: { 'all-hours': 2 },
      quinta: { 'all-hours': 2 },
      sexta: { 'all-hours': 0 },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
  },
  'CAMPO GRANDE': {
    LOAS: {
      'all-days': {
        'all-hours': 3,
      },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
    PREVIDENCIÁRIO: {
      segunda: { 'all-hours': 3 },
      terça: { 'all-hours': 3 },
      quarta: { 'all-hours': 3 },
      quinta: { 'all-hours': 3 },
      sexta: { 'all-hours': 0 },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
  },
  'SÃO PAULO': {
    LOAS: {
      'all-days': {
        'all-hours': 6,
      },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
    PREVIDENCIÁRIO: {
      segunda: { 'all-hours': 1 },
      terça: { 'all-hours': 1 },
      quarta: { 'all-hours': 1 },
      quinta: { 'all-hours': 1 },
      sexta: { 'all-hours': 0 },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
  },
  'BELO HORIZONTE': {
    LOAS: {
      'all-days': {
        'all-hours': 4,
        '12:00': 2,
        '12:30': 2,
        '13:00': 2,
        '13:30': 2,
        '17:00': 3,
      },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
    PREVIDENCIÁRIO: {
      'all-days': {
        'all-hours': 0,
      },
    },
  },
  'ANGRA DOS REIS': {
    LOAS: {
      'all-days': {
        'all-hours': 18,
      },
      sábado: { 'all-hours': 0 },
      domingo: { 'all-hours': 0 },
    },
    PREVIDENCIÁRIO: {
      'all-days': {
        'all-hours': 0,
      },
    },
  },
};
