import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Modal, Button,
} from 'react-bootstrap';

export default function ContatoWhatsaap(
  {
    telefoneprincipal,
    obstelefoneprincipal,
    telefonesegundo,
    obstelefonesegundo,
    telefoneoutros,
    obstelefoneoutros,
  },
) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button variant="success" type="button" onClick={handleOpenModal}>
        <span>
          <FontAwesomeIcon icon={faWhatsapp} />
        </span>
      </Button>

      <Modal show={isOpen} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Entrar em Contato:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { telefoneprincipal && (
            <p>
              Telefone:
              &nbsp;
              <Button
                href={`https://wa.me/55${telefoneprincipal}`}
                target="_blank"
                rel="noopener noreferrer"
                variant="success"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </Button>
              {obstelefoneprincipal && (
                <p>
                  Observação Telefone:
                  &nbsp;
                  {obstelefoneprincipal}
                </p>
              )}
            </p>
          )}
          { telefonesegundo && (
            <p>
              2º Telefone:
              &nbsp;
              <Button
                href={`https://wa.me/55${telefonesegundo}`}
                target="_blank"
                rel="noopener noreferrer"
                variant="success"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </Button>
              {obstelefonesegundo && (
                <p>
                  Observação 2º Telefone:
                  &nbsp;
                  {obstelefonesegundo}
                </p>
              )}
            </p>
          )}
          { telefoneoutros && (
            <p>
              3º Telefone:
              &nbsp;
              <Button
                href={`https://wa.me/55${telefoneoutros}`}
                target="_blank"
                rel="noopener noreferrer"
                variant="success"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </Button>
              {obstelefoneoutros && (
                <p>
                  Observação 3º Telefone:
                  &nbsp;
                  {obstelefoneoutros}
                </p>
              )}
            </p>
          )}
          { !telefoneprincipal && !telefonesegundo && !telefoneoutros && (
            <p>
              Essa pessoa não tem nenhum telefone para contato.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ContatoWhatsaap.defaultProps = {
  telefoneprincipal: null,
  obstelefoneprincipal: null,
  telefonesegundo: null,
  obstelefonesegundo: null,
  telefoneoutros: null,
  obstelefoneoutros: null,
};

ContatoWhatsaap.propTypes = {
  telefoneprincipal: PropTypes.string,
  telefonesegundo: PropTypes.string,
  telefoneoutros: PropTypes.string,
  obstelefoneprincipal: PropTypes.string,
  obstelefonesegundo: PropTypes.string,
  obstelefoneoutros: PropTypes.string,
};
