const eventName = {
  ATENDIMENTO: 'Atendimento',
  LANCADO: 'Lançado',
  PREENCHIDO: 'Preenchido',
  PRIMEIRA_REVISAO: 'Primeira revisão',
  SEGUNDA_REVISAO: 'Segunda revisão',
  DEFERIDO: 'Deferido',
  ENTRADA: 'Entrada',
  PEDIDO_DE_DILACAO: 'Pedido de Dilação',
  DILACAO_CONCEDIDA: 'Dilação Concedida',
  EXIGENCIA_CUMPRIDA: 'Exigência cumprida',
  EXIGENCIA_NAO_CUMPRIDA: 'Exigência não cumprida',
  GUIA_DE_PG_GRATUIDADE: 'Guia de PG: Gratuidade',
  GUIA_DE_PG_DESCONTO: 'Guia de PG: Desconto',
  GUIA_DE_PG_COMISSAO: 'Guia de PG: Comissão',
  PERICIA_AGENDADA: 'Pericia Agendada',
  RETIRADO: 'Retirado',
  RETORNOU: 'Retornou',
  RECEBIMENTO: 'Recebimento',
  DEVOLVIDO: 'Devolvido',
  DOCUMENTO_ENTREGUE: 'Documento Entregue',
  NOTIFICACAO_PERICIA_AGENDADA: 'Notificação Cliente - data da perícia',
  MARCACAO_AVALIACAO_SOCIAL: 'Marcação da avaliação social',
  NOTIFICACAO_AVALIACAO_SOCIAL: 'Notificação Cliente - data da avaliação social',
  RECEBIDO: 'Recebido',
  REGISTRO: 'Realização do registro',
  ATUALIZACAO: 'Atualização de dados',
  PAGAMENTO_STATUS: 'Status do pagamento',
  SITUACAO_STATUS: 'Status da Situação',
  REMARCACAO: 'Remarcação de Agendamento',
  AGENDAMENTO_CANCELADO: 'Agendamento cancelado',
  VAI_RETORNAR: 'Vai retornar',
  EXIGENCIA_NAO_CUMPRIDA_OK: 'Exigência não cumprida ok',
  INDEFERIMENTO_VISTO: 'Indeferimento visto',
  DEFERIMENTO_VISTO: 'Deferimento visto',
  ARQUIVO: 'Arquivo',
  NOVA_ENTRADA: 'Nova entrada',
  ARQUIVO_MORTO: 'Arquivo Morto',
  ENVIADO_EXIGENCIA: 'Enviado para Exigência',
  COBRAR_DOCUMENTO: 'Cobrar Documento',
  PROTOCOLO_DE_ATENDIMENTO: 'Protocolo de Atendimento',
  DOCUMENTO_SOLICITADO: 'Documento Solicitado',
  DOCUMENTO_RECEBIDO: 'Documento Recebido',
  DEIXAR_INDEFERIR: 'Deixar Indeferir',
  DILACAO: 'Dilação',
  NAO_ASSINOU_CONTRATO: 'Não assinou contrato',
};

export default eventName;
