import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Context } from '../Context/AuthContext';

import { Saladesituacao } from '../pages/Saladesituacao';
import { Portfolio } from '../pages/Portfolio';
import { Operacoesrio } from '../pages/Portfolio/operacoesrio';
import { Eventos } from '../pages/Portfolio/eventos';

import { Login } from '../pages/User/Login';
import { AddUserLogin } from '../pages/User/AddUserLogin';
import { RecoverPassword } from '../pages/User/RecoverPassword';
import { UpdatePassword } from '../pages/User/UpdatePassword';

import { Mapa } from '../pages/Mapa';
import { Dashboard } from '../pages/Dashboard';
import { Ajuda } from '../pages/Ajuda';
import { Users } from '../pages/User/Users';
import { AddUser } from '../pages/User/AddUser';
import { ViewUser } from '../pages/User/ViewUser';
import { EditUser } from '../pages/User/EditUser';

import { Pessoas } from '../pages/Pessoa/Pessoas';
import { AddPessoa } from '../pages/Pessoa/AddPessoa';
import { EditPessoa } from '../pages/Pessoa/EditPessoa';
import { AgendamentoRealizado } from '../pages/Pessoa/AgendamentoRealizado';

import AtendimentosPresenciais from '../pages/AtendimentosPresenciais/Index';

import { Cadastros } from '../pages/Cadastro/Cadastros';
import Contatos from '../pages/Contatos/Index/index';

import { ViewPessoa } from '../pages/Pessoa/ViewPessoa';

import { Ramais } from '../pages/Ramal/Ramais';
import { Elinks } from '../pages/Elink/Elinks';

import { Chamados } from '../pages/Chamados/Chamados';
import { AddChamado } from '../pages/Chamados/AddChamado';
import { ViewChamado } from '../pages/Chamados/ViewChamado';
import { EditChamado } from '../pages/Chamados/EditChamado';

import { RioAguas } from '../pages/RioAguas';
import { EditRioAguas } from '../pages/EditRioAguas';
import { ViewRioAguas } from '../pages/ViewRioAguas';

import { EditUserPassword } from '../pages/Profile/EditUserPassword';
import { ViewProfile } from '../pages/Profile/ViewProfile';
import { EditProfile } from '../pages/Profile/EditProfile';
import { EditProfilePassword } from '../pages/Profile/EditProfilePassword';
import { EditProfileImage } from '../pages/Profile/EditProfileImage';
import { EditUserImage } from '../pages/Profile/EditUserImage';
import { Blank } from '../pages/Blank';
import Atendimento from '../pages/AtendimentosPresenciais/Atendimento';
import Relatorios from '../pages/Admin/Relatorios/Index';

const perfilMaster = '99';

// eslint-disable-next-line react/prop-types
function CustomRoute({ isPrivate, isRestrictedForMaster, ...rest }) {
  const { authenticated, handleLogout } = useContext(Context);

  if (isPrivate && !authenticated) {
    return <Redirect to="/" />;
  }

  if (isRestrictedForMaster && localStorage.getItem('perfilId') !== perfilMaster) {
    handleLogout();
    return <Redirect to="/login" />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} />;
}

export default function RoutesAdm() {
  return (
    <Switch>
      <CustomRoute exact path="/" component={Login} />
      <CustomRoute exact path="/blank" component={Blank} />
      <CustomRoute exact path="/sala" component={Saladesituacao} />
      <CustomRoute exact path="/login" component={Login} />
      <CustomRoute exact path="/ajuda" component={Ajuda} isPrivate />
      <CustomRoute exact path="/add-user-login" component={AddUserLogin} />
      <CustomRoute exact path="/recover-password" component={RecoverPassword} />
      <CustomRoute exact path="/update-password/:key" component={UpdatePassword} />

      <CustomRoute exact isPrivate path="/mapa" component={Mapa} />
      <CustomRoute exact isPrivate path="/dashboard" component={Dashboard} />
      <CustomRoute exact isPrivate path="/portfolio" component={Portfolio} />
      <CustomRoute exact isPrivate path="/operacoesrio" component={Operacoesrio} />
      <CustomRoute exact isPrivate path="/rioaguas" component={RioAguas} />
      <CustomRoute exact isPrivate path="/eventos" component={Eventos} />

      <CustomRoute exact isPrivate path="/rioaguas" component={RioAguas} />
      <CustomRoute exact isPrivate path="/edit-rioaguas/:id" component={EditRioAguas} />
      <CustomRoute exact isPrivate path="/view-rioaguas/:id" component={ViewRioAguas} />

      <CustomRoute exact isPrivate isRestrictedForMaster path="/users" component={Users} />
      <CustomRoute exact isPrivate isRestrictedForMaster path="/add-user" component={AddUser} />
      <CustomRoute exact isPrivate isRestrictedForMaster path="/view-user/:id" component={ViewUser} />
      <CustomRoute exact isPrivate isRestrictedForMaster path="/edit-user/:id" component={EditUser} />

      <CustomRoute exact isPrivate path="/pessoas" component={Pessoas} />
      <CustomRoute exact isPrivate path="/add-pessoa" component={AddPessoa} />
      <CustomRoute exact isPrivate path="/view-pessoa/:id" component={ViewPessoa} />
      <CustomRoute exact isPrivate path="/edit-pessoa/:id" component={EditPessoa} />
      <CustomRoute exact isPrivate path="/agendamentorealizado/:id" component={AgendamentoRealizado} />

      <CustomRoute exact isPrivate path="/cadastros" component={Cadastros} />

      <CustomRoute exact isPrivate path="/ramais" component={Ramais} />
      <CustomRoute exact isPrivate path="/elinks" component={Elinks} />

      <CustomRoute exact isPrivate path="/chamados" component={Chamados} />
      <CustomRoute exact isPrivate path="/add-chamado" component={AddChamado} />
      <CustomRoute exact isPrivate path="/view-chamado/:id" component={ViewChamado} />
      <CustomRoute exact isPrivate path="/edit-chamado/:id" component={EditChamado} />

      <CustomRoute exact isPrivate path="/pessoas/:id/atendimento/:atendimentoId" component={Atendimento} />
      <CustomRoute exact isPrivate path="/edit-user-password/:id" component={EditUserPassword} />
      <CustomRoute exact isPrivate path="/view-profile" component={ViewProfile} />
      <CustomRoute exact isPrivate path="/edit-profile" component={EditProfile} />
      <CustomRoute exact isPrivate path="/edit-profile-password" component={EditProfilePassword} />
      <CustomRoute exact isPrivate path="/edit-profile-image" component={EditProfileImage} />
      <CustomRoute exact isPrivate path="/edit-user-image/:id" component={EditUserImage} />

      <CustomRoute exact isPrivate path="/atendimentos-presenciais" component={AtendimentosPresenciais} />

      <CustomRoute exact isPrivate isRestrictedForMaster path="/admin/relatorios" component={Relatorios} />

      <CustomRoute exact isPrivate path="/Contatos" component={Contatos} />

    </Switch>
  );
}
